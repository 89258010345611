<template>
  <div uk-height-viewport class="uk-flex uk-flex-middle">
    <div class="uk-width-3-3@m uk-width-1-3@s m-auto rounded">
      <div class="uk-child-width-2-2@m uk-grid-collapse" uk-grid>
        <div class="uk-card-default p-5 rounded">
          <div class="mb-4">
            <h3 class="mb-3"> {{ $t('forgot_password.header') }}</h3>
          </div>
          <form @submit.prevent="onSubmit()" novalidate>
            <div class="uk-form-group">
              <label class="uk-form-label"> {{ $t('forgot_password.email_telephone') }} </label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-mail"></i>
                </span>
                <input class="uk-input" v-model="form.username">
              </div>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label">
                {{ $t('general.password') }}
              </label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-lock"></i>
                </span>
                <input class="uk-input" v-model="form.password" :type="passwordType ? 'text' : 'password'">
                <i class="icon-feather-eye open-password" @click="showPassword('passwordType')"></i>
              </div>
              <ol class="bg-white W-100 pt-1 pl-2 mb-1">
                <li v-if="passwordValidation.hasUpperLetter" class="uk-text-small m-0" style="color:grey;">
                  <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                    $t('errors.password_must_have_upper_latter') }}
                </li>
                <li v-if="passwordValidation.hasLowerLetter" class="uk-text-small m-0" style="color:grey;">
                  <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                    $t('errors.password_must_have_lower_latter') }}
                </li>
                <li v-if="passwordValidation.hasNumber" class="uk-text-small m-0" style="color:grey;">
                  <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                    $t('errors.password_must_have_number') }}
                </li>
                <li v-if="passwordValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:grey;">
                  <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                    $t('errors.password_must_more_than_7') }}
                </li>
                <li v-if="!passwordValidation.hasUpperLetter" class="uk-text-small m-0" style="color:red;">
                  <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
                    $t('errors.password_must_have_upper_latter') }}
                </li>
                <li v-if="!passwordValidation.hasLowerLetter" class="uk-text-small m-0" style="color:red;">
                  <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
                    $t('errors.password_must_have_lower_latter') }}
                </li>
                <li v-if="!passwordValidation.hasNumber" class="uk-text-small m-0" style="color:red;">
                  <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_have_number')
                  }}
                </li>
                <li v-if="!passwordValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:red;">
                  <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_more_than_7')
                  }}
                </li>
              </ol>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label">
                {{ $t('general.password_confirm') }}
              </label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-lock"></i>
                </span>
                <input class="uk-input" v-model="form.password_confirmation"
                  :type="confirmPasswordType ? 'text' : 'password'">
                <i class="icon-feather-eye open-password" @click="showPassword('confirmPasswordType')"></i>
              </div>
              <ol class="bg-white W-100 pt-1 pl-2 mb-1">
                <li v-if="passwordConfirmValidation.hasUpperLetter" class="uk-text-small m-0" style="color:grey;">
                  <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                    $t('errors.password_must_have_upper_latter') }}
                </li>
                <li v-if="passwordConfirmValidation.hasLowerLetter" class="uk-text-small m-0" style="color:grey;">
                  <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                    $t('errors.password_must_have_lower_latter') }}
                </li>
                <li v-if="passwordConfirmValidation.hasNumber" class="uk-text-small m-0" style="color:grey;">
                  <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                    $t('errors.password_must_have_number') }}
                </li>
                <li v-if="passwordConfirmValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:grey;">
                  <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                    $t('errors.password_must_more_than_7') }}
                </li>
                <li v-if="!passwordConfirmValidation.hasUpperLetter" class="uk-text-small m-0" style="color:red;">
                  <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
                    $t('errors.password_must_have_upper_latter') }}
                </li>
                <li v-if="!passwordConfirmValidation.hasLowerLetter" class="uk-text-small m-0" style="color:red;">
                  <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
                    $t('errors.password_must_have_lower_latter') }}
                </li>
                <li v-if="!passwordConfirmValidation.hasNumber" class="uk-text-small m-0" style="color:red;">
                  <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_have_number')
                  }}
                </li>
                <li v-if="!passwordConfirmValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:red;">
                  <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_more_than_7')
                  }}
                </li>
              </ol>
            </div>

            <div class="uk-alert-danger" uk-alert v-if="errors">
              <a class="uk-alert-close" uk-close></a>
              <p style="color:red;">
                <i class="uil-exclamation-triangle mr-2"></i>{{ errors }}
              </p>
            </div>

            <div class="uk-alert-success" uk-alert v-if="message">
              <a class="uk-alert-close" uk-close></a>
              <p style="color:green;"><i class="uil-exclamation-triangle mr-2"></i>{{ message }}</p>
            </div>

            <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
              <div class="uk-width-expand@s">
                <button type="button" class="btn mr-2" @click="$router.push({ name: 'login' })">
                  <i class="uil-arrow-left"></i> {{ $t('general.back') }}
                </button>
              </div>
              <div class="uk-width-auto@s">
                <button type="submit" class="btn btn-default">
                  {{ $t('forgot_password.send') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import { RESET_PASSWORD, SET_ERROR } from "@/core/services/store/auth.module";

export default {
  name: "ForgotPassword",
  data() {
    return {
      form: {
        username: null,
        password: null,
        password_confirmation: null,
        token: null,
        alias: null,
      },
      message: null,
      passwordType: false,
      confirmPasswordType: false,
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
  },
  methods: {
    passwordValidation() {
      let validation = {
        'hasLowerLetter': false,
        'hasUpperLetter': false,
        'hasNumber': false,
        'hasAtLeast8Letters': false,
      };
      if (this.form.password) {
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;

        if (this.form.password.match(lowerCaseLetters)) {
          validation.hasLowerLetter = true;
        }
        if (this.form.password.match(upperCaseLetters)) {
          validation.hasUpperLetter = true;
        }
        if (this.form.password.match(numbers)) {
          validation.hasNumber = true;

        }
        if (this.form.password.length >= 8) {
          validation.hasAtLeast8Letters = true;
        }
      }

      return validation
    },
    passwordConfirmValidation() {
      let validation = {
        'hasLowerLetter': false,
        'hasUpperLetter': false,
        'hasNumber': false,
        'hasAtLeast8Letters': false,
      };
      if (this.form.password_confirmation) {
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;

        if (this.form.password_confirmation.match(lowerCaseLetters)) {
          validation.hasLowerLetter = true;
        }
        if (this.form.password_confirmation.match(upperCaseLetters)) {
          validation.hasUpperLetter = true;
        }
        if (this.form.password_confirmation.match(numbers)) {
          validation.hasNumber = true;

        }
        if (this.form.password_confirmation.length > 8) {
          validation.hasAtLeast8Letters = true;
        }
      }

      return validation
    },
    showPassword(element) {
      switch (element) {
        case "passwordType":
          this.passwordType = !this[element];
          break;
        case "confirmPasswordType":
          this.confirmPasswordType = !this[element];
          break;
      }
    },
    onSubmit() {
      let form = this.form

      if (['production', 'staging'].includes(process.env.NODE_ENV)) {
        form.alias = window.location.hostname.split(".")[0];
      }

      // if (this.form.email == null || this.form.email.length < 4) {
      //   let errorMessage = this.$t("validation.required_field").replace(":NAME", this.$t('forgot_password.email'))
      //   this.$store.commit(SET_ERROR, errorMessage)
      //   return false
      // }

      const mail_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (!mail_regex.test(this.form.email)) {
      //   let errorMessage = this.$t("validation.email").replace(":attribute", this.$t('forgot_password.email'))
      //   this.$store.commit(SET_ERROR, errorMessage)
      //   return false
      // }

      if (this.form.password == null || this.form.password.length < 7) {
        this.$store.commit(SET_ERROR,
          this.$t('errors.password_must_more_than_7')
        );
        return false
      }
      if (this.form.password !== this.form.password_confirmation) {
        let errorMessage = this.$t('errors.password_dont_match')
        this.$store.commit(SET_ERROR, errorMessage)
        return false
      }
      // send forgot password request
      this.$store.dispatch(RESET_PASSWORD, form)
        .then((response) => {
          this.message = this.$t('messages.reset_password_successfully')
          setTimeout(() => this.$router.push({ name: 'login' }), 1000);
        })
    }
  },
  mounted() {
    this.$store.commit(SET_ERROR, '')
    this.form.token = this.$route.params.token
    if (!this.form.token) {
      this.$router.push({ name: "login" })
    }

    let url = window.location.href
    if (process.env.NODE_ENV === 'production') {
      this.form.alias = window.location.hostname.split(".")[0];
    }
  }
}
</script>

<style scoped>
.open-password {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.open-password:hover {
  cursor: pointer;
}
</style>
